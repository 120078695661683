<template>
  <div
    :data-uuid="task.uuid"
    class="OneTask"
  >
    <div
      v-if="!isEditing"
      class="one-task"
    >
      <div>
        <el-checkbox
          :value="isChecked"
          @change="checkTask"
        />
      </div>
      <div>
        <span
          v-if="task.description !== null"
          :title="task.description"
          class="description"
          @dblclick="showEditor"
          @click="showTimeEntries = !showTimeEntries"
        >
          {{ task.description }}
        </span>
        <span
          v-else
          @dblclick="showEditor"
        >
          (no description)
        </span>
      </div>
      <div>
        <transition name="editor">
          <el-tag
            v-if="task.isStarted"
            type="success"
            size="small"
            class="active-tag"
            color="#5daf34"
          >
            Active
          </el-tag>
        </transition>
      </div>
      <div
        class="gray-text"
      >
        <span
          v-if="task.project"
          :title="task.project.name"
          class="project-name"
        >
          <i class="fas fa-project-diagram" />
          {{ task.project.name }}
        </span>
      </div>
      <div
        v-show="false"
      >
        <span v-if="task.eta">
          Progress: {{ task.progress }}%
        </span>
      </div>
      <div>
        <div v-if="task.timeEntries.count() || task.total">
          <span v-if="date !== today">
            <span
              v-show="date !== today"
              class="gray-text"
            >
              Total:
              <span :class="{ 'red': task.isExceeded }">
                {{ task.totalFormatted }}
              </span>
            </span>
          </span>
          <span v-if="date === today && task.total">
            <span
              v-show="date === today"
              class="gray-text"
            >
              Total:
              <span :class="{ 'red': task.isExceeded }">
                {{ task.totalFormatted }}
              </span>
            </span>
            <br>
          </span>
          <span v-if="date === today">
            Today:
            <span :class="{ 'red': task.isExceeded }">
              {{ task.totalFormatted }}
            </span>
          </span>
        </div>
        <div v-else>
          <span class="gray-text">
            0 min
          </span>
        </div>
      </div>
      <div>
        <span title="Stop task">
          <el-button
            v-if="task.isStarted"
            type="danger"
            plain
            class="stop-button start-stop-buttons"
            @click="stopTask"
          >
            <i
              :class="{
                'el-icon-loading': task.isStopping,
                'fa-stop': task.isStarted && !task.isStopping,
              }"
              class="fa"
            />
          </el-button>
        </span>
        <span title="Start task">
          <el-button
            v-if="task.isStopped"
            type="success"
            plain
            class="start-button start-stop-buttons"
            @click="startTask"
          >
            <i
              :class="{
                'el-icon-loading': task.isStarting,
                'fa-play': task.isStopped && !task.isStarting,
              }"
              class="fa"
            />
          </el-button>
        </span>
      </div>
      <div>
        <el-popover
          ref="menu"
          v-model="visibleMenu"
          placement="top-end"
          trigger="click"
        >
          <el-button
            type="plain"
            class="stop-button"
            plain
            @click.prevent="showEditor"
          >
            <i class="el-icon-edit" />
          </el-button>
          <el-button
            type="danger"
            class="stop-button"
            plain
            @click.prevent="confirmDialogVisible = true"
          >
            <i class="el-icon-delete" />
          </el-button>
        </el-popover>

        <!--suppress HtmlUnknownAttribute -->
        <span v-popover:menu>
          <i class="el-icon-more" />
        </span>
      </div>
      <div>
        <i
          v-if="!showTimeEntries"
          class="el-icon-arrow-down"
          @click="showTimeEntries = true"
        />
        <i
          v-if="showTimeEntries"
          class="el-icon-arrow-up"
          @click="showTimeEntries = false"
        />
      </div>
    </div>
    <div
      v-show="showTimeEntries"
      class="time-entries"
    >
      <div class="add-time-entry">
        <div>
          <template v-if="task.timeEntries.count()">
            <div
              v-for="timeEntry in task.timeEntries.all()"
              :key="timeEntry.uuid"
            >
              <one-time-entry
                :time-entry="timeEntry"
                :task="task"
                @delete="deleteTimeEntry"
                @stop-task="stopTask"
              />
            </div>
          </template>
          <div
            v-if="!task.timeEntries"
            class="gray-text"
          >
            No time has been recorded yet
          </div>
        </div>
        <div>
          <el-button
            type="primary"
            size="mini"
            plain
            @click="addingTimeEntry = true"
          >
            Add Time Entry
          </el-button>
        </div>
      </div>
      <time-entry-editor
        v-if="addingTimeEntry"
        :task="task"
        @create="createTimeEntry"
        @close-editor="closeEditor"
      />
      <div
        v-if="task.eta"
        class="progress-time"
      >
        <el-progress
          :percentage="task.progress"
          :stroke-width="18"
          :status="task.isExceeded ? 'exception' : null"
          text-inside
        />
        <div>
          <div class="total">
            {{ task.totalFormatted }} / {{ task.etaFormatted }}
          </div>
        </div>
      </div>
      <div
        class="tag-line"
      >
        <el-tag
          v-for="tag in task.tags.all()"
          :key="tag.uuid"
          :type="isDeletingTag(tag) ? 'info' : 'success'"
          :class="isDeletingTag(tag) ? 'loading' : 'loading2'"
          closable
          hit
          @close="deleteTag(tag)"
        >
          {{ tag.name }}
        </el-tag>
        <el-autocomplete
          v-if="isEditingTag"
          ref="inputTagEditor"
          v-model="tagEditorValue"
          :suffix-icon="isSavingTag ? 'el-icon-loading' : ''"
          :disabled="isSavingTag"
          :fetch-suggestions="queryTags"
          :trigger-on-focus="false"
          value-key="name"
          highlight-first-item
          class="input-tag-editor"
          size="mini"
          @keyup.enter.native="saveTag"
          @blur="scheduleCancelTagBlur"
          @select="saveTag"
        />
        <el-button
          v-else
          class="button-new-tag"
          size="small"
          type="info"
          plain
          @click="editTag"
        >
          + Tag
        </el-button>
      </div>
    </div>
    <div v-if="addingTimeEntry || isEditing">
      <task-editor
        v-if="isEditing"
        :editing-task="true"
        :task="task"
        @update-task="updateTask"
        @close-editor="closeEditor"
        @delete-task="confirmDialogVisible = true"
      />
    </div>
    <!--Confirm dialog-->
    <el-dialog
      :visible.sync="confirmDialogVisible"
      title="Delete task"
      width="30%"
    >
      <span>
        It will not be undone. Continue?
      </span>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="confirmDialogVisible = false">
          No
        </el-button>
        <el-button
          type="primary"
          autofocus
          @click="deleteTask"
        >
          Yes
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';

import { Task, TagRepository, Tag } from '@timeragent/core';

import notification from '../../../mixins/notification';
import { showError } from '../../../notification';

export default {
  components: {
    TimeEntryEditor: () => import('./TimeEntryEditor.vue'),
    TaskEditor: () => import('./TaskEditor.vue'),
    OneTimeEntry: () => import('./TimeEntry.vue'),
  },

  mixins: [notification],

  props: {
    task: {
      type: Task,
      required: true,
      default: () => new Task(),
    },
    checkedTasks: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      isEditing: false,
      isEditingTag: false,
      addingTimeEntry: false,
      confirmDialogVisible: false,
      visibleMenu: false,
      confirmStopActiveTask: false,
      showTimeEntries: false,
      tagEditorValue: '',
      isSavingTag: false,
      deletingTags: [],
      scheduledCancelTagBlur: null,
      tagRepository: new TagRepository(),
    };
  },

  computed: {
    ...mapGetters([
      'date',
      'timerId',
      'user',
    ]),
    todayTotal() {
      if (!this.task.timeEntries.count()) {
        return moment.duration(0, 'seconds');
      }

      return this.task.timeEntries.reduce((prev, cur) => {
        let { endTime } = cur;

        if (!endTime) endTime = moment().format('YYYY-MM-DD HH:mm:ss');

        return moment.duration(moment(endTime, 'YYYY-MM-DD HH:mm:ss')
          .diff(moment(cur.startTime, 'YYYY-MM-DD HH:mm:ss')))
          .add(prev);
      }, null);
    },
    isChecked() {
      return !!this.checkedTasks.find(taskUuid => taskUuid === this.task.uuid);
    },
    today() {
      return moment().format('YYYY-MM-DD');
    },

    tags() {
      return this.tagRepository.dataset;
    },
  },

  methods: {
    ...mapActions([
      'setTimerId',
      'setCurrentDate',
    ]),

    async updateTask(task) {
      try {
        this.task = await task.update();

        this.isEditing = false;
      } catch (e) {
        console.error(e);
        showError(e.message);
      }
    },

    deleteTask() {
      this.confirmDialogVisible = false;
      this.$emit('delete', this.task);
      this.checkTask(false);
    },

    createTimeEntry(timeEntry) {
      this.task.timeEntries.push(timeEntry);
      this.task.total = moment.duration(this.task.total)
        .add(timeEntry.getDuration())
        .asMilliseconds();
    },

    deleteTimeEntry(timeEntry) {
      this.task.timeEntries = this.task.timeEntries.filter(te => te.uuid !== timeEntry.uuid);

      this.task.total -= timeEntry.getDuration();
    },

    showEditor() {
      this.isEditing = true;
    },

    async queryTags(queryString, cb) {
      await this.tagRepository.many({
        // filters: {
        //   name: queryString,
        // },
      });

      const results = queryString ? this.tags.all().filter(this.createFilter(queryString)) : this.tags.all();

      cb(results);
    },

    createFilter(queryString) {
      return tag => tag.name.toLowerCase().search(queryString.toLowerCase()) >= 0
        && !this.task.tags.some(taskTag => taskTag.name === tag.name);
    },

    editTag() {
      this.isEditingTag = true;
      this.$nextTick(() => {
        this.$refs.inputTagEditor.$refs.input.focus();
      });
    },

    scheduleCancelTagBlur() {
      this.scheduledCancelTagBlur = window.setTimeout(this.cancelTag, 200);
    },

    cancelTag() {
      if (this.isSavingTag) {
        return;
      }

      this.isEditingTag = false;
      this.tagEditorValue = '';
    },

    async saveTag(tag) {
      if (this.scheduledCancelTagBlur) {
        window.clearTimeout(this.scheduledCancelTagBlur);
      }

      if (!this.tagEditorValue) {
        this.isEditingTag = false;

        return;
      }

      if (this.isSavingTag) {
        return;
      }

      this.isSavingTag = true;

      if (!(tag instanceof Tag)) {
        tag = new Tag({ name: this.tagEditorValue });
        await tag.save();
      }

      this.task.tags.push(tag);
      await this.task.update();

      this.isSavingTag = false;
      this.isEditingTag = false;
      this.tagEditorValue = '';
    },

    isDeletingTag(tag) {
      return this.deletingTags.includes(tag.uuid);
    },

    async deleteTag(tag) {
      this.task.tags = this.task.tags.filter(t => t.uuid !== tag.uuid);

      try {
        await this.task.update();
      } catch (e) {
        console.error(e);
      }
    },

    closeEditor() {
      this.isEditing = false;
      this.addingTimeEntry = false;
    },

    async startTask() {
      await this.task.start();

      if (this.date !== moment().toDate()) {
        this.setCurrentDate(moment().startOf('day').toDate());
      }

      this.closeEditor();
    },

    async stopTask() {
      await this.task.stop();
      this.clearInterval();
    },

    clearInterval() {
      clearInterval(this.timerId);
      document.title = this.$route.meta.name;
      // noinspection JSCheckFunctionSignatures
      this.setTimerId({ timerId: 0 });
    },
    time(time) {
      return moment(time, 'HH:mm:ss').format('HH:mm');
    },
    checkTask(value) {
      this.$emit('check', {
        taskUuid: this.task.uuid,
        value,
      });
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/css"
  scoped
>

  .el-icon-more {
    margin: 5px;
  }

  .el-col {
    padding: 5px;
    line-height: 28px;
  }

  .el-icon-caret-right {
    font-size: 20px;
    cursor: pointer;
  }

  .start-button {
    padding: 5px;
  }

  .stop-button {
    padding: 5px;
  }

  .stop-button-buttons {
    width: 35px;
    height: 35px;
    margin-top: -8px;
  }

  .active-tag {
    color: #fff;
  }

  .description {
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
  }

  .red {
    color: #f56c6c;
  }

  .editor-enter-active {
    transition: opacity .5s;
  }

  .editor-enter, .editor-leave-to {
    opacity: 0;
  }

  .total {
    float: right;
    margin-top: 5px;
  }

  .one-task {
    display: grid;
    grid-template: auto / 15px auto 53px 140px 130px 35px 25px 16px;
    grid-gap: 10px;
    margin-top: 20px;
  }

  .add-time-entry {
    display: grid;
    grid-template: auto / auto auto;
    justify-content: space-between;
    padding: 15px 0;
  }

  .progress-time {
    padding-bottom: 20px;
  }

  .input-tag-editor {
    width: 120px;
  }

  .input-tag-editor::v-deep input {
    height: 26px;
  }

  .button-new-tag {
    height: 26px;
    padding: 6px 9px;
  }

  .tag-editor {
    display: inline-block;
    margin-right: 10px;
  }

  .time-entries {
    padding-left: 15px;
  }

  .tag-line {
    margin-bottom: 15px;
    margin-top: 10px;
  }

  .el-tag::v-deep .el-tag__close {
    margin-left: -3px;
  }

  .el-tag.loading::v-deep .el-tag__close::before {
    content: "\E61e";
  }

  .el-tag.loading::v-deep .el-tag__close {
    animation: rotating 2s linear infinite;
    pointer-events: none;
  }
</style>

<style
  lang="stylus"
  rel="stylesheet/css"
>
  .OneTask {
    padding: 5px 20px 15px 20px;
  }

  .el-icon-arrow-down, .el-icon-arrow-up, .el-icon-more {
    cursor: pointer;
  }

  .el-popover {
    min-width: 65px;
  }

  .el-tag {
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 0 10px 0 7px;
    height: 26px;
    line-height: 24px;
  }
</style>
